const modalOpenClass = 'modal-open';
const mobileMessagesLayoutMQ = window.matchMedia('(max-width: 767.98px)');
let messagesOpen = false;

const handleMessengerResize = (): void => {
  if (messagesOpen) {
    if (!mobileMessagesLayoutMQ.matches && document.body.classList.contains(modalOpenClass)) {
      // Allow body scroll on larger screens
      document.body.classList.remove(modalOpenClass)
    } else if (mobileMessagesLayoutMQ.matches && !document.body.classList.contains(modalOpenClass)) {
      // Add modal open class if messenger sidebar is open to prevent body scrolling
      document.body.classList.add(modalOpenClass)

      // Scroll to messages end
      const messagesSidebar = document.querySelectorAll('#messenger-sidebar .b-sidebar-body')
      const messages = document.querySelectorAll('#messenger-sidebar .messages-layout')
      if (messagesSidebar.length !== 0 && messages.length !== 0) {
        messagesSidebar[0].scrollTop = messagesSidebar[0].scrollHeight
      }
    }
  }
}

export function addMessengerResizeHandler(): void {
  window.addEventListener('resize', handleMessengerResize)
}

export function removeMessengerResizeHandler(): void {
  window.removeEventListener('resize', handleMessengerResize)
  document.body.classList.remove(modalOpenClass)
  messagesOpen = false;
}

// Prevents scrolling body when messenger sidebar is open
export function toggleBodyScroll(visible: boolean): void {
  messagesOpen = visible;

  if (messagesOpen && mobileMessagesLayoutMQ.matches) {
    document.body.classList.add(modalOpenClass)
  } else if (!messagesOpen) {
    document.body.classList.remove(modalOpenClass)
  }
}