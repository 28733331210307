import {Component, Watch} from "vue-property-decorator"
import {VNode} from "vue"
import {BIconPatchQuestionFill} from "bootstrap-vue"
import Messenger from "@/components/Messenger";
import {Message, RequestNewMessagePayload} from "@/_modules/request/types";
import BackendClientMixin from "@/mixins/BackendClientMixin";
import {authStore} from "@/store";
import _ from "lodash";
import {DealType} from "@/types";
import {ProposalNewMessagePayload} from "@/_modules/proposal/types";
import {requestServiceStore} from "@/_modules/request/store/request-service-store";
import {proposalDemandStore} from "@/_modules/proposal/store/proposal-demand-store";
import {BasicErrorHandler} from "@/utils/errorHandler";
import * as ResponseError from "@/utils/errors"
import {ErrorCodes} from "@/constants/APIconstants";
import {chatStore, Contact, Deal} from "@/_modules/message-center/store/chat-store";
import PageHeader from "@/components/layout/PageHeader";
import AppIconVerified from "@/components/icons/AppIconVerified";
import AppIconClose from "@/components/icons/AppIconClose";
import { addMessengerResizeHandler, removeMessengerResizeHandler, toggleBodyScroll } from "@/utils/message-center";

export class ChatsErrorHandler extends BasicErrorHandler {
  protected async handleBackendError(e: ResponseError.BackendError): Promise<void> {
    switch(e.code) {
      case ErrorCodes.ProposalRecordNotFound:
        this.errorMessageKey = 'err_proposal_record_not_found'
        break
      case ErrorCodes.ServiceRequestNotFound:
        this.errorMessageKey = 'err_service_request_not_found'
        break
      case ErrorCodes.ServiceRequestStatusIncorrect:
        this.errorMessageKey = 'err_service_request_status_incorrect'
        break
      case ErrorCodes.ProposalStatusIncorrect:
        this.errorMessageKey = 'err_proposal_status_incorrect'
        break
      case ErrorCodes.MessageIdNotFound:
        this.errorMessageKey = 'err_message_id_not_found'
        break
      default:
        await super.handleBackendError(e)
    }
  }
}

const byAlphabet = (): (a: Contact, b: Contact) => number => {
  return ((a, b) => a.userName.localeCompare(b.userName))
}

export const chatsErrorHandler = new ChatsErrorHandler()

@Component({
  name: 'MessagingCenter',
  components: {
    BIconPatchQuestionFill
  }
})
export default class extends BackendClientMixin {

  private selectedChat: null | {
    collapseId: string | null;
    dealId: string;
    dealType: DealType;
    userId: string;
  } = null
  private selectedContact: null | Contact = null

  private messages: Message[] = []

  @Watch('selectedChat')
  public onSelectedChatSwitch(): void {
    this.messages = []
  }

  public mounted(): void {
    this.messages = []
    this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
      if (!isJustShown && collapseId === this.selectedChat?.collapseId) {
        this.selectedChat = null
        this.selectedContact = null
      }
    })

    addMessengerResizeHandler()
  }

  public async beforeDestroy(): Promise<void> {
    await chatStore.disconnectChatStream()
    chatStore.clearChatMessages()

    removeMessengerResizeHandler()
  }

  @Watch('messages')
  public async watchMessages(): Promise<void> {
    if (this.messages[this.messages.length - 1]?.lastInBatch && this.isChatSelected) {
      const userId = authStore.authInfo!.userId
      const senderMessages = this.messages.filter(it => {
        return it.userId !== userId
      })

      const id = senderMessages.length > 0 ? senderMessages[senderMessages.length - 1].id : this.messages[this.messages.length - 1].id!

      await this.withRequest(chatStore.setEventAsReaded({
        msgId: id!,
        dealType: this.selectedChat!.dealType.toUpperCase()
      }), chatsErrorHandler, true)
    }

    if (this.selectedChat !== null) {
      chatStore.setEventAsReadedInternal(this.selectedChat.dealId)
    }

  }

  private get contacts(): Contact[] {
    const contacts = _.cloneDeep(chatStore.getContacts)

    return contacts.sort(byAlphabet())
  }

  protected get isChatSelected(): boolean {
    return this.selectedChat !== null
  }

  private async onSendMessage(messagePayload: Message): Promise<void> {
    if (this.selectedChat?.dealType.toLowerCase() === DealType.Request) {
      const payload: RequestNewMessagePayload = {
        message: messagePayload,
        requestId: this.selectedChat.dealId
      }
      const sentMsg = await this.withRequest(requestServiceStore.sendMessage(payload), chatsErrorHandler, true)
      chatStore.addMessage(sentMsg)
    }
    if (this.selectedChat?.dealType.toLowerCase() === DealType.Proposal) {
      const payload: ProposalNewMessagePayload = {
        message: messagePayload,
        proposalId: this.selectedChat.dealId
      }
      const sentMsg = await this.withRequest(proposalDemandStore.sendMessage(payload), chatsErrorHandler, true)
      chatStore.addMessage(sentMsg)
    }
  }

  private async onChatSelected(event: MouseEvent, singleContact: Contact, deal: Deal, id: string): Promise<void> {
    event.preventDefault();

    if (this.selectedChat?.dealId === deal.dealId) {
      return;
    }

    await chatStore.disconnectChatStream()
    chatStore.clearChatMessages()
    this.messages = []

    this.selectedChat = {
      collapseId: id,
      dealId: deal.dealId,
      dealType: deal.dealType,
      userId: authStore.authInfo!.userId!
    }
    this.selectedContact = singleContact

    await chatStore.establishChatConnection(this.selectedChat)
    this.messages = chatStore.getChatMessages

    chatStore.setEventAsReadedInternal(deal.dealId)
  }

  protected redirectToDeal(): void {
    if (this.selectedChat?.dealType.toLowerCase() === DealType.Request) {
      this.$router.push({
        path: `messaging-center/request/${this.selectedChat.dealId}`
      })
    }
    if (this.selectedChat?.dealType.toLowerCase() === DealType.Proposal) {
      this.$router.push({
        path: `messaging-center/proposal/${this.selectedChat.dealId}`
      })
    }
  }

  private onCloseChat(): void {
    this.selectedChat = null
    this.selectedContact = null
    this.messages = []
  }

  private renderCardHeader(singleContact: Contact, i: number): VNode {
    const contact = chatStore.contacts.find(it => {
      return it.userId === singleContact.userId
    })
    const totalUnreaded = contact!.deals.filter(deal => deal.dealUnreadedMessageCount > 0).length
    return (
      <b-link
        class="d-flex flex-row text-decoration-none text-body py-6 px-8"
        v-b-toggle={`${i}`}
      >
        <div class="flex-fill">
          <h2 class="mt-0 mb-3 username">{contact!.userName}</h2>
          <div class="d-flex">
            {contact!.userVerified &&
              <span class="small text-nowrap mr-4 d-flex align-items-center">
                <AppIconVerified class="app-icon-lg mt-0 align-top mr-2 text-success" />
                {this.translation('msg_param_verified_true')}
              </span>
            }
            {!contact!.userVerified &&
              <span class="small text-nowrap mr-4 d-flex align-items-center">
                <b-icon-patch-question-fill class="app-icon-lg mt-0 align-top mr-2" variant="danger-dark" />
                {this.translation('msg_param_verified_false')}
              </span>
            }
          </div>
        </div>
        {totalUnreaded > 0 &&
          <div class="mt-2 ml-2">
            <b-avatar
              class="badge-amount"
              variant={'danger'}
            >
              {totalUnreaded}
            </b-avatar>
          </div>
        }
      </b-link>
    )
  }

  private renderEachDealItem(singleContact: Contact, id: string): VNode {
    return (
      <b-list-group class="rounded-0">
        {singleContact.deals.map((singleDeal) => {
          const selected = singleDeal.dealId === this.selectedChat?.dealId
          return (
            <b-list-group-item class={`deal-single-chat${selected ? ' active' : ''}`} href="#" aria-controls="messenger-sidebar" onClick={(event: MouseEvent) => this.onChatSelected(event, singleContact, singleDeal, id)}>
              <div class="d-flex flex-row align-items-center">
                <span class="deal-single-chat-title flex-fill">
                  {singleDeal.dealTitle}
                </span>
                {singleDeal.dealUnreadedMessageCount > 0 &&
                  <b-badge
                    variant="danger"
                    class="badge-event-label ml-2"
                  >
                    {this.translation('lbl_badge_new')}
                  </b-badge>
                }
              </div>
            </b-list-group-item>
          )
        })}
      </b-list-group>
    )
  }

  private renderUsersList(): VNode {
    return (
      <div role="tablist">
        {this.contacts.map((singleContact, i) => {
          return (
            <b-card no-body={true} class="mb-2 rounded">
              <b-card-header header-tag="header" class="chat-item p-0 bg-transparent" role="tab">
                {this.renderCardHeader(singleContact, i)}
              </b-card-header>
              <b-collapse ref={`userBox${i}`} id={String(i)} accordion={`accordion-chat-self`} role="tabpanel">
                <b-card-body class="pt-0 px-6 pb-9">
                  {this.renderEachDealItem(singleContact, i.toString())}
                </b-card-body>
              </b-collapse>
            </b-card>
          )
        })}
      </div>
    )
  }

  public render(): VNode {
    return (
      <b-container fluid="xl" class="active-chat">
        <b-row>
          <PageHeader
            headerClass="my-8 mb-md-10"
            title={this.translation('route_messaging-center')}
            wideHeader={true}
          >
            <p>{this.translation('messaging_center_description')}</p>
          </PageHeader>
        </b-row>
        {this.contacts.length === 0 &&
        <div class="bg-gray-100 rounded overflow-hidden d-flex align-items-center justify-content-center mb-8 my-md-12">
          <div class="p-6 d-flex flex-column align-items-center justify-content-center text-center no-results-found">
            <p class="small mb-0">{this.translation(`msg-center.msg_no_messaging_center_events_found`)}</p>
          </div>
        </div>
        }
        <b-row>
          <b-col cols="12" md="5" lg="4">
            {this.renderUsersList()}
          </b-col>
          <b-col cols="12" md="7" lg="8">
            {!this.isChatSelected && this.contacts.length !== 0 &&
              <div class="bg-gray-100 border rounded overflow-hidden d-none d-md-flex align-items-center justify-content-center py-8 py-md-12">
                <div class="p-6 d-flex flex-column align-items-center justify-content-center text-center no-results-found">
                  <p class="small mb-0">{this.translation(`msg-center.msg_choose_deal`)}</p>
                </div>
              </div>
            }
            <b-sidebar
              body-class="pb-4 pb-md-0"
              class="d-md-flex"
              id="messenger-sidebar"
              right
              text-variant="body"
              visible={this.isChatSelected}
              width="100%"
              onChange={(visible: boolean) => {
                if (!visible) {
                  this.onCloseChat()
                }

                toggleBodyScroll(visible)
              }}
            >
              <template slot="header-close">
                <AppIconClose class="text-primary" />
              </template>
              <template slot="default">
                {this.selectedChat && 
                  <div>
                    <Messenger
                      isMessageCenter={true}
                      onMessengerError={(err: string) => (this.errorMessageKey = err)}
                      v-model={this.messages}
                      viewForCurrentUserById={authStore.authInfo?.userId}
                      otherPersonName={this.selectedContact?.userName}
                      onSendMessage={((messagePayload: Message) => this.onSendMessage(messagePayload))}
                    />
                    <div class="d-flex justify-content-end mt-3 mx-2 mx-md-0">
                      <b-button
                        class="flex-fill flex-sm-grow-0"
                        variant="outline-primary"
                        onClick={() => this.redirectToDeal()}
                      >
                        {this.selectedChat?.dealType.toLowerCase() === DealType.Request ? this.translation('btn.proceed-to-request') : this.translation('btn.proceed-to-proposal')}
                      </b-button>
                    </div>
                  </div>
                }
              </template>
            </b-sidebar>
          </b-col>
        </b-row>
      </b-container>
    )
  }
}
